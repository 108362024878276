@import "../../themes/responsive.scss";
@import "../../themes/variables.scss";

.travel-advisory-container {
  margin-bottom: 60px;
  .heading-col {
    background-color: $header-bg-color;
    padding: 80px 0px;
    @include breakpoint(sm) {
      padding: 20px 0px;
    }
    .title {
      @include breakpoint(sm) {
        font-size: 28px;
      }
    }
  }
  .advisory-card {
    .ant-card-body {
      padding-top: 70px;
      .top-info {
        position: absolute;
        top: 15px;
        right: 15px;
        .risk {
          .ant-typography {
            color: #ff000091;
          }
        }
      }
    }
  }
}
