.footer {
  background-color: black;
  .company-dec {
    .logo {
      display: flex;
      align-items: center;
      color: white;
      text-transform: uppercase;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }
    .description {
      color: white;
    }
  }
  .footer-links {
    color: white;
    font-weight: 500;
  }
  .copyright-col {
    border-top: 1px solid white;
    text-align: right;
    .ant-typography {
      color: white;
    }
  }
}
