@import "../../themes/responsive.scss";

.header {
  padding-left: 70px;
  padding-right: 70px;
  border-bottom: 1px solid black;
  background-color: white;
  @include breakpoint(md) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include breakpoint(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .logo {
    display: flex;
    align-items: center;
    color: black;
    text-transform: uppercase;
    &:hover {
      color: black !important;
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }
  .page-links-col {
    .page-links-row {
      a {
        color: black;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    .menu-btn {
      font-size: 20px;
      border-left: 1px solid black;
      padding-left: 20px;
    }
  }
}

.side-drawer {
  .ant-drawer-content {
    background-color: black;
    .close-icon {
      color: white;
      font-size: 30px;
      display: flex;
      justify-content: right;
    }
    .ant-list-item {
      border-bottom: none;
      padding-bottom: 15px;
      padding-top: 25px;
      .page-link {
        color: white;
        font-weight: bold;
      }
    }
  }
}
