$primary: #03045e !important;

$black-1: #1a1a1a !important;

$grey-1: #f2f2f1 !important;
$grey-2: #9b9b9b !important;
$grey-3: #ebebeb !important;
$grey-4: #d7d7d7 !important;
$grey-5: #7c7d89 !important;
$grey-6: #f6f6f6 !important;
$grey-7: #545151 !important;

$header-bg-color: #f3f1e8;
