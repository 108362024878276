@import "../../themes/responsive.scss";
@import "../../themes/variables.scss";

.blogs-container {
  margin-bottom: 60px;
  .heading-col {
    background-color: $header-bg-color;
    padding: 80px 0px;
    @include breakpoint(sm) {
      padding: 20px 0px;
    }
    .title {
      @include breakpoint(sm) {
        font-size: 28px;
      }
    }
  }
  .content {
    margin-top: 50px;
    .blogs {
      .blog-details {
        padding-top: 32px;
        padding-bottom: 32px;
        @include breakpoint(sm) {
          padding-top: 0px;
          padding-bottom: 12px;
        }
      }
      .blog-image {
        .ant-image {
          height: 100%;
          .ant-image-img {
            border-radius: 10px;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .paragraph-text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        line-clamp: 5;
        -webkit-box-orient: vertical;
      }
      .hr-line {
        display: inline-block;
        width: 40px;
        margin-bottom: 4px;
        margin-left: 16px;
        margin-right: 16px;
      }
    }
    .blog-type-categories {
      .blog-type-item {
        border-bottom: 1px solid $grey-1;
        cursor: pointer;
      }
    }
  }
}
