$breakpoints: (
  xxl: "(min-width: 1600px)",
  xl: "(min-width: 1200px) and (max-width: 1599px)",
  lg: "(min-width: 992px) and (max-width: 1199px)",
  md: "(min-width: 768px) and (max-width: 991px)",
  sm: "(max-width: 767px)",
);

@mixin breakpoint($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @media #{$size} {
    @content;
  }
}
