@import "../../themes/responsive.scss";

.country-immigration-info-container {
  .title {
    @include breakpoint(sm) {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }
  .explore-image {
    max-height: 600px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 12px;
    img {
      border-radius: 12px;
      width: 70%;
      @include breakpoint(sm) {
        border-radius: 8px;
        width: 90%;
      }
    }
  }
  .content-row {
    .info-image {
      max-height: 400px;
      padding: 3px 0px;
      overflow: hidden;
      object-fit: cover;
      border-radius: 6px;
      img {
        border-radius: 6px;
        width: 35%;
      }
    }
    .list-type-col {
      ul {
        column-count: 3;
        @include breakpoint(sm) {
          column-count: 2;
        }
      }
    }
    .link-type-col {
      .ant-btn-link {
        display: list-item;
        list-style-type: disc;
        width: fit-content;
      }
    }
  }
}
