@import "../../themes/responsive.scss";

.blog-details-container {
  .back-icon {
    font-size: 24px;
  }
  .blog-title {
    font-size: 42px;
    @include breakpoint(sm) {
      font-size: 28px;
    }
  }
  .blog-image {
    .ant-image {
      width: 100%;
      img {
        width: 100%;
        max-width: 100%;
        border-radius: 8px;
        max-height: 600px;
        object-fit: cover;
      }
    }
  }
  .paragraphs {
    .image {
      .ant-image {
        width: 70%;
        @include breakpoint(sm) {
          width: 90%;
        }
        img {
          border-radius: 6px;
          max-height: 450px;
        }
      }
    }
    .list-type-col {
      ul {
        column-count: 3;
        @include breakpoint(sm) {
          column-count: 2;
        }
      }
    }
  }
  .hr-line {
    display: inline-block;
    width: 40px;
    margin-bottom: 4px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .similar-blogs {
    .similar-blog-image {
      .ant-image-img {
        border-radius: 8px;
      }
    }
  }
}
