@import "./themes/spacing.scss";
@import "./themes/fonts.scss";
@import "./themes/responsive.scss";
@import "./themes/variables.scss";

.primary {
  color: $primary;
}

.black-1 {
  color: $black-1;
}

.grey-1 {
  color: $grey-1;
}

.grey-2 {
  color: $grey-2;
}

.grey-3 {
  color: $grey-3;
}

.grey-4 {
  color: $grey-4;
}

.grey-5 {
  color: $grey-5;
}

.grey-6 {
  color: $grey-6;
}

.grey-7 {
  color: $grey-7;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.app-content {
  background-color: #fffefa;
}

.hover-red {
  &:hover {
    .ant-typography {
      transition: all 0.3s ease;
      color: red;
    }
  }
}

.hover-primary {
  &:hover {
    .ant-typography {
      transition: all 0.3s ease;
      color: $primary;
    }
  }
}

.text-center-mobile {
  @include breakpoint(sm) {
    text-align: center;
  }
}

.disclaimer-modal {
  .ant-modal-title {
    font-size: 24px;
  }
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.full-height {
  height: 100%;
  max-height: 100%;
}

.text-underline {
  text-decoration: underline;
}
