@import "../../../themes/responsive.scss";
@import "../../../themes/variables.scss";

.blogs-and-articles {
  .blog-type {
    font-family: sans-serif;
  }
  .main-blog {
    cursor: pointer;
    .title {
      transition: all 0.3s ease;
    }
    .image {
      border-radius: 6px;
      max-height: 300px;
    }
  }
  .other-blogs {
    cursor: pointer;
    .image {
      border-radius: 4px;
      min-height: 80px;
      @include breakpoint(sm) {
        max-width: 100%;
      }
    }
    .title {
      transition: all 0.3s ease;
    }
  }
  .go-btn-text {
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .go-btn {
    min-width: 25px !important;
    width: 25px;
    height: 25px;
    font-size: 11px;
    padding: 0px;
    margin-left: 5px;
    background-color: $primary;
    .anticon {
      font-size: 11px;
    }
  }
}
