@import "../../themes/responsive.scss";

.explore-immigration-options-container {
  .title {
    @include breakpoint(sm) {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }
  .explore-image {
    overflow: hidden;
    object-fit: cover;
    border-radius: 8px;
    img {
      border-radius: 8px;
    }
  }
  .content-row {
    .list {
      li {
        margin: 20px 0px;
      }
    }
    .content-image {
      border-radius: 8px;
      img {
        border-radius: 8px;
      }
    }
    .quotes-box {
      border: 1px solid black;
      border-radius: 8px;
      padding: 45px !important;
      font-style: italic;
      .ant-typography {
        @include breakpoint(sm) {
          font-size: 16px;
        }
      }
    }
    .country-select {
      .ant-select-selection-item {
        text-align: left;
      }
    }
  }
}
