@import "../../themes/responsive.scss";

.home-container {
  margin: auto !important;
  .top-row {
    cursor: pointer;
    .title {
      transition: all 0.4s ease-in;
      @include breakpoint(sm) {
        font-size: 28px;
      }
      &:hover {
        color: #06065ea6;
      }
    }
    .image {
      text-align: right;
      @include breakpoint(sm) {
        text-align: center;
      }
      .preview-image {
        max-width: 600px;
        max-height: 500px;
        border-radius: 8px;
        width: 100%;
        img {
          border-radius: 8px;
          max-height: 450px;
        }
        @include breakpoint(sm) {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
