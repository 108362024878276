@import "../../themes/responsive.scss";
@import "../../themes/variables.scss";

.about-us-container {
  margin-bottom: 60px;
  .heading-col {
    background-color: $header-bg-color;
    padding: 80px 0px;
    @include breakpoint(sm) {
      padding: 20px 0px;
    }
    .title {
      @include breakpoint(sm) {
        font-size: 28px;
      }
    }
  }
  .content {
    margin: auto;
    padding: 0px 20px;
    @include breakpoint(sm) {
      margin: 10px 0px;
      padding: 0px 20px;
    }
    .section-heading {
      font-weight: bold;
      @include breakpoint(sm) {
        font-size: 22px;
      }
    }
  }
  .display-image {
    width: 100%;
    height: 100%;
    max-height: 300px;
    border-radius: 10px;
    @include breakpoint(sm) {
      max-height: 100%;
    }
  }
  .commitment-row {
    @include breakpoint(sm) {
      flex-direction: column-reverse;
    }
  }
}
