@import "../../themes/responsive.scss";

.contact-us-container {
  width: 95%;
  margin: auto !important;
  padding: 80px 20px;
  @include breakpoint(sm) {
    width: 100%;
    padding: 30px 0px;
  }
  .image-col {
    display: flex;
    justify-content: center;
    .display-image {
      width: 100%;
      height: 100%;
      max-height: 400px;
      border-radius: 10px;
    }
  }
  .contact-details {
    .ant-list-item {
      border-bottom: none;
      padding-top: 0px;
      .ant-list-item-meta {
        align-items: center;
        .ant-list-item-meta-title {
          font-weight: normal;
          font-size: 16px;
        }
      }
    }
  }
  .contact-form {
    .submit-btn {
      font-size: 14px;
      width: 150px;
      height: 45px;
      border: 1px solid black;
    }
  }
}
