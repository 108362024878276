@import "../../themes/responsive.scss";
@import "../../themes/variables.scss";

.privacy-policy-container {
  .heading-col {
    background-color: $header-bg-color;
    padding: 80px 0px;
    @include breakpoint(sm) {
      padding: 50px 0px;
    }
    .title {
      @include breakpoint(sm) {
        font-size: 28px;
      }
    }
  }
  .content {
    margin: 60px 0px;
    padding: 0px 30px;
    @include breakpoint(sm) {
      margin: 40px 0px;
      padding: 0px 20px;
    }
    .privacy-heading-col {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      .heading {
        font-size: 26px;
        @include breakpoint(sm) {
          font-size: 22px;
        }
      }
    }

    .privacy-section-col {
      .title {
        font-size: 20px;
      }
    }

    .section-heading {
      font-size: 26px;
      @include breakpoint(sm) {
        font-size: 22px;
      }
    }
  }
}
