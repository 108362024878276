@import "../../themes/responsive.scss";

.app-layout {
  background-color: white;
  .app-content {
    min-height: 80vh;
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}
