@import "../../../themes/responsive.scss";

.explore-visa-options {

  .skeleton-borders {
    border-radius: 12px !important;
  }

  .visa-option-title {
    font-size: 38px;
    @include breakpoint(sm) {
      font-size: 28px;
    }
  }

  .main-option-row {
    .image {
      border-radius: 8px;
      width: 100%;
      img {
        border-radius: 8px;
      }
    }
  }
  .options-row {
    .option {
      .image {
        border-radius: 8px;
      }
    }
  }
}
