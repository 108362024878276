@import "../../themes/responsive.scss";
@import "../../themes/variables.scss";

.terms-of-use-container {
  .heading-col {
    background-color: $header-bg-color;
    padding: 80px 0px;
    @include breakpoint(sm) {
      padding: 50px 0px;
    }
    .title {
      @include breakpoint(sm) {
        font-size: 28px;
      }
    }
  }

  .content {
    margin: 60px 0px;
    padding: 0px 30px;
    @include breakpoint(sm) {
      padding: 0px 20px;
    }
    .term-of-use-section:not(:last-of-type) {
      border-bottom: 1px solid black;
    }
    .section-heading {
      font-weight: bold;
      @include breakpoint(sm) {
        font-size: 22px;
      }
    }
  }
}
